#splash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 10000;
}

#splash img {
  width: 15vw;
  height: 15vw;

  -webkit-transform-origin: 50% 80%;
  -webkit-transform: rotate(-15deg);
  transform-origin: 50% 80%;
  transform: rotate(-15deg);

  animation: splash-logo 1.5s ease-out infinite;
}

@keyframes splash-logo {
  25% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: rotate(15deg);
    transform-origin: 50% 80%;
    transform: rotate(15deg);
  }
  45% {    
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: rotate(-15deg);
    transform-origin: 50% 80%;
    transform: rotate(-15deg);
  }
  75% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: rotate(15deg);
    transform-origin: 50% 80%;
    transform: rotate(15deg);
  }
}