/* #trusty-app{
    width:100vw;
    height:100vh;
    background-image:url('../../../assets/images/trusty-app/interface.png');
    background-position:center;
    background-size:contain;
    background-repeat:no-repeat;
}

.OnBoardingModal .makeStyles-modalSignup-29{
    max-width:35%;    
}

.OnBoardingModal .logo{
    width: 70%;
    margin-top:40px;
}

.OnBoardingModal form{
    max-width: 70%;
    margin:0 auto;
}


.OnBoardingModal .logoContainer > div{
    text-align:center!important;
}

@media screen and (max-width:1200px){
    .OnBoardingModal .makeStyles-modalSignup-29{
        max-width:55%;    
    }
} */

#trusty-app{
    background:url(../../../assets/images/trusty-app/background_bashboard.png);
    background-size: cover;
}

#trusty-app .MuiContainer-root{
    padding:0;  
    margin:0;
    background:url(../../../assets/images/trusty-app/background_bashboard.png);
    background-size: cover;
}

.flexContainer{
    width:100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.sidebar{
    width:14.5%;
    height: 100vh;
    background:url(../../../assets/images/trusty-app/sidebar.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.contentFlex{
    width:84.5%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background:url(../../../assets/images/trusty-app/content.png);
    background-size: 100%;
    background-repeat: no-repeat;
    margin:0 2vw;
}

.headerOnBoarding{
    width:100%;
    height:15vh;
}

.contentOnBoarding{
    width:100%;
    height:85vh;
}

.menuIcon{
    width:50px;
    height: 50px;
    background:url(../../../assets/images/trusty-app/icona.png);
    background-size: cover;
    position:fixed;
    bottom:15px;
    right:15px;
}