.MuiPaper-root > button{
    display: none!important;
}

@media only screen and (max-width: 1500px) {
    .back-image-bg img{
        width:58vh;
    }
}

@media only screen and (max-width: 1300px) {

    .back-image-bg img{
        width: 85%;
        top: 4vh;
    }
}

@media only screen and (max-width: 1250px) {
    .back-image-bg img{
        width: 50vh;
        top: 7vh;
    }
}

@media only screen and (max-width: 1140px) {
    .back-image-bg img{
        width: 55vh;
        top: 6vh;
    }

    #buttonHowItWork{
       margin-left:0px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 950px) {
    .back-image-bg img{
        width: 41vh;
        top: 14vh;
    }
}

@media only screen and (max-width: 950px) {
    .back-image-bg img{
        width: 37vh;
        top: 19vh;
    }

    .enterMobile{
        display: block!important;
    }

}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1366px) 
  and (orientation: portrait) 
  {

    .MuiDrawer-paperAnchorLeft{
        max-width:35vw;
        width: 35vw;
    }

    .MuiDrawer-paperAnchorLeft ul{
        padding:15px;
    }

    .MuiDrawer-paperAnchorLeft ul li{
        width:100%;
        float:left;
        border-bottom:1px solid #eaeaea;
        padding:0;
    }

    .MuiDrawer-paperAnchorLeft > button{
        display:none;
    }

    #buttonHowItWork {
        margin-left: 0px;
        display:none!important;
    }
    
    .MuiDrawer-paperAnchorLeft a, .MuiDrawer-paperAnchorLeft button{
        font-family: 'FreedokaOne' !important;
        font-size:1rem;
    }

}

@media only screen and (max-width: 768px) {
    .back-image-bg img{
        width: 35vh;
        top: 22.5vh;
    }
    .MuiDrawer-paperAnchorLeft{
        max-width:63vw;
    }
}

@media only screen and (max-width: 685px) {
    #about-us .back-image-bg, #about-us .title-page{
        width:100%;
        float:left;
    }

    #buttonHowItWork{
        display:none!important;
        margin-left:0;
    }

    .desktopModal .MuiPaper-root {
        max-width: 100%!important;
        margin:0!important;
    }
}

@media only screen and (max-width: 600px) {
    header .MuiToolbar-root > .right{
        display: none;
    }

    .title-page h3{
        font-size:1.2em;
    }

    #contact-us  .back-image-bg{
        top:24vh;
        margin-right:10vw!important;
    }
    
    #header-container button{
        margin-top:0;
    }

    .MuiDrawer-paperAnchorLeft{
        max-width:63vw;
    }

    .MuiDrawer-paperAnchorLeft .right{
        opacity:1!important;
    }

    .MuiDrawer-paperAnchorLeft > button{
        display:none;
    }

    .MuiDrawer-paperAnchorLeft .right li button span:first-child,
    .MuiDrawer-paperAnchorLeft .right li a span:first-child {
        justify-content: center!important;
        text-align: center;
    }

    .MuiDrawer-paperAnchorLeft a, .MuiDrawer-paperAnchorLeft button{
        font-family: 'FreedokaOne' !important;
        font-size:1rem;
    }

    /* .mobileModal button{
        margin-bottom:15vw;
    } */


    #how-it-works .top,
    #about-us .top,
    #press .top {
        height: initial !important;
        padding-top: 80px;
        min-height: 30vh !important;
    }
    #press .top {
        height: 150vw!important;
        max-height: 150vw!important;
        align-items: start!important;
        background: rgb(251, 238, 202);    
    }
    #press .top .back-image {
        background-position: 79% 93%;
        background-size: 64vh;
        background-repeat: no-repeat;
    }
    #about-us .title-page h1,
    #press .title-page h1 {
        font-size: 8vh;
    }
    #press .contact-section {
        padding: 30px 0;
    }
    #about-us section a img {
        opacity: initial;
    }
    footer .right-content {
        position: initial;
        right: initial;
    }

    #how-it-works .top {
        background: linear-gradient(180deg, rgb(200, 224, 232) 0%, rgb(200, 224, 232) 80%, rgb(255, 255, 255) 80%) !important;
    }
    #about-us .top {
        background: linear-gradient(180deg, rgba(216, 234, 204, 1) 0%, rgba(216, 234, 204, 1) 80%, rgba(255, 255, 255, 1) 80%) !important;
    }
    #press .top {
        background: linear-gradient(180deg, rgb(251, 238, 202) 0%, rgb(251, 238, 202) 94%, rgb(255, 255, 255) 94%) !important;
    }

    .aboutFlex{
        display:flex;
        flex-direction:column;
        justify-self: start;
    }

    .contactFlex{
        display:flex;
        flex-direction:row;
        justify-self: start;
    }

    #about-us .top{
        height: 180vw!important;
        max-height: 180vw!important;
        display: block!important;
    }

    .back-image-bg img{
        width: 100%;
        position: relative;
        top:0vh;
    }
}

@media only screen and (max-width: 400px) {
    .title-page h1 {
        font-size: 3.5rem;
    }

    #about-us .top{
        height: 200vh!important;
        max-width: 200vh!important;
    }

    .back-image-bg{
        width: 80%;
        position: relative;
        margin-top:0vh;
        height: 65vh;
    }
}

@media only screen and (max-width: 375px) {
    .back-image-bg{
        width: 80%!important;
        height: 55vh;
        position: relative;
        margin-top:0;
        left:10%;
    }
}



@media only screen and (min-width: 676px) {
    #intro-text h1,
    #home .scheda-home .scheda-home-text h1 {
        font-size: 5vh;
    }
    #top-home h1 {
        font-size: 4.5vh;
    }
    #home .scheda-home .scheda-home-text h2 {
        font-size: 1.2rem;
    }
    #press .top {
        background: linear-gradient(180deg, rgb(251, 238, 202) 0%, rgb(251, 238, 202) 80%, rgb(255, 255, 255) 80%) !important;
        height: 60vh;
        max-height: 60vh;
    }
    #press .top .back-image {
        background-position: 79% 77%;
        background-size: 65vh;
        background-repeat: no-repeat;
    }

}

@media only screen and (min-width: 768px) {
    #press .top {
        background: linear-gradient(180deg, rgb(251, 238, 202) 0%, rgb(251, 238, 202) 80%, rgb(255, 255, 255) 80%) !important;
        height: 55vh;
        max-height: 55vh;
    }

}

@media only screen and (min-width: 960px) {

    .MuiDrawer-paperAnchorLeft ul{
        display:block;
    }
    .MuiDrawer-paperAnchorLeft ul li{
        width:100%;
        float:left;
        border-bottom:1px solid #eaeaea;
        padding:0;
        margin:5px 0;
    }

    .MuiDrawer-paperAnchorLeft button, .MuiDrawer-paperAnchorLeft li:last-child a{
        width:100%;
    }

    .MuiDrawer-paperAnchorLeft .link-menu:first-child a{
        padding-left: 15px;
    }


}

@media only screen and (min-width: 1024px) {
    #press .top .back-image {
        background-position: 79% 77%;
        background-size: 95vh;
        background-repeat: no-repeat;
    }

    #press .top{
        height: 65vh;
        max-height: 65vh;
    }
}

@media only screen and (min-width: 1280px) {
    #intro-text h1,
    #home .scheda-home .scheda-home-text h1 {
        font-size: 6vh;
    }
    /* #top-home h1 {
        font-size: 5.5vh;
    } */
    #home .scheda-home .scheda-home-text h2 {
        font-size: 1.8rem;
    }
}

@media only screen and (min-width: 1500px) {
    .MuiContainer-maxWidthLg {
        max-width: 1190px !important;
    }
    #intro-text h1,
    #home .scheda-home .scheda-home-text h1 {
        font-size: 5vh;
    }
    #top-home h1 {
        font-size: 5vh;
    }
    #home .scheda-home .scheda-home-text h2 {
        font-size: 2.2rem;
    }

    .back-image-bg img{
        width:58vh;
    }

}

@media only screen and (min-width: 400px) and (max-width: 530px){ 
    #about-us .top{
        height: 180vw!important;
        max-height: 180vw!important;
        display: block!important;
    }
    
    .back-image-bg{
        width: 38vh;
        position: relative;
        margin-top: -3vh;
    }

    .circleContainer{
        background: white;
    }
    
}

@media only screen and (width: 1366px) and (height: 625px){ 
    #about-us .top{
        height:80vh;
        max-height:80vh;
    }
    
    .back-image-bg img{
        width: 78vh;
        margin-left: 8vw;
    }

    .circleContainer{
        background: white;
    }
}

@media only screen and (width: 1280px) and (min-height: 510px) and (max-height: 680px){ 
    #about-us .top{
        height:80vh;
        max-height:80vh;
    }
    
    .back-image-bg img{
        width: 70vh;
        margin-left: 8vw;
    }

    ul.prezzo, ul.prezzo li{
        padding:4px 0!important;
    }
}

@media screen and (min-width: 1000px) and (max-height:770px) {
    #pricing1, #pricing2, #pricing3{
        max-height: 80vh!important;
        margin-top:0!important;
    }

    .desktopModal.filosofia button[aria-label="Close"]{
        top:30px!important;
    }

    .inner-price h1{
        margin-top:0!important;
    }

    ul.prezzo {
        margin-top:5vh!important;
    }

    ul.prezzo li{
        padding:5px 0!important;
    }

    .inner-price > div:last-child button{
        margin-top:-10px!important;
    } 

    .oneHalfMinus p{
        font-size:1.1rem!important;
    }

    .closeContainer button {
        margin-right: -50px!important;
        z-index: 10000;
        position: relative;
    }

    .desktopModal .MuiPaper-root{
        max-height: 95vh;
        overflow-y:scroll;
    }

    .desktopModal.contacts .MuiFormControl-root{
        padding-top:0px!important;
    }

    .desktopModal.contacts form{
        margin-top:0;
    }

    .desktopModal.contacts .scrollY{
        padding-bottom:50px;
    }
    .desktopModal.filosofia .MuiDialogContent-root{
        height: 145%!important;
    }
}
